import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  public buildEmail = () => {
    const l0 = 'think';
    const l1 = 'jakub';
    const l2 = 'kolecki';
    const l3 = 'gmail';
    const l4 = 'com';
    const l5 = '.';
    const l6 = 'in';
    const l7 = 'js';
    const l8 = '@';

    window.location.href = `mailto:${l0}${l6}${l7}${l5}${l1}${l5}${l2}${l8}${l3}${l5}${l4}?Subject=Question%20from%20thinkinjs.com`;
  }
}
